.cardWrapper{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-top: 2rem;
}

@media screen and (min-width: 768px) {
    .cardWrapper{
        flex-direction: row;
        justify-content: space-between;
    }
}

@media screen and (min-width: 992px) {

}