.sectionWrapper{
    width: 100%;
    align-items: center;
    gap: 2rem;
}
.introductionWrapper{
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 2rem;
}

@media screen and (min-width: 768px) {
    .sectionWrapper{
        width: 90%;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
        gap: 1.5rem;
    }
    .introductionWrapper{
        width: 48%;
        text-align: left;
    }
}

@media screen and (min-width: 992px) {
    .sectionWrapper{
        justify-content: space-between;
        gap: 2.5rem;
    }
    .introductionWrapper{
        width: 47%;
        align-self: flex-start;
    }
}