.button {
  padding: 0.7rem 1.5rem;
  background-color: #ff9c31;
  border: none;
  border-radius: 1.5rem;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000000;
  font-size: 1rem;
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: none;
}
.button:hover {
  transform: scale(1.02);
}
