/*shared*/
.background {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.75);
  min-height: 8vh;
  width: 100%;
}

/*header mobile*/
.layoutWrapperM {
  min-height: 9vh;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 3;
  padding: 0 1rem;
}
.homelink {
  display: flex;
  flex-grow: 0;
  height: 100%;
}
.imgWrapper {
  display: flex;
  align-items: center;
  height: 100%;
  gap: 0.5rem;
}
.img {
  height: 40px;
  object-fit: contain;
}
.icon {
  font-size: 40px;
  z-index: 2;
}

/*menu*/
.mobileMenuBackground {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #ffffff91;
}
.mobileMenuContentWrapper {
  width: 80%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1.5rem;
  padding: 5rem 1.5rem;
  background-color: #8dbde8;
}
.mobileLinkWrapper {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.link {
  text-decoration: none;
  text-transform: uppercase;
  color: #000000;
  font-size: 1.125rem;
  font-weight: 600;
  letter-spacing: 0.02rem;
}
.sublink {
  padding-left: 1.5rem;
}

/*language*/
.selectLangWrapper {
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}
/* .languageMenuM {
  position: absolute;
  top: -0.5rem;
  left: -0.5rem;
  display: flex;
  flex-direction: column;
  z-index: 3;
  background-color: #8dbde8;
} */
/* .languageMenuD {
  position: absolute;
  top: 150%;
  right: 0;
  display: flex;
  flex-direction: column;
  z-index: 3;
  background-color: rgba(255, 255, 255, 0.6);
} */
/* .reverse {
  flex-direction: column-reverse;
} */
/* .languageWrapper {
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem;
} */

@media screen and (min-width: 768px) {
  .layoutWrapperM {
    padding: 1.5rem;
  }
  .homelink {
    height: 2.5rem;
  }
}

@media screen and (min-width: 992px) {
  .background {
    min-height: 11vh;
  }
  .layoutWrapperD {
    height: 11vh;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    z-index: 3;
    padding: 1.5rem;
  }
  .img {
    height: 100%;
    width: auto;
  }
  .leftSideWrapper {
    display: flex;
    gap: 1.5rem;
  }
  .linkWrapper {
    display: flex;
    align-items: center;
    gap: 1.5rem;
  }
  .link {
    text-decoration: none;
    text-transform: uppercase;
    color: #000000;
    font-size: 1.125rem;
    font-weight: 500;
    letter-spacing: 0.02rem;
  }
  .button {
    padding: 0.5rem 1.2rem;
    background-color: #ff9c31;

    border: none;
    border-radius: 1.5rem;

    font-family: 'Poppins', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #000000;
    font-size: 1rem;
    font-weight: 500;
    text-transform: uppercase;
    /* 
        box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.25);
        -webkit-box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.25);
        -moz-box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.25); */
  }
  .button:hover {
    transform: scale(1.02);
  }
}
