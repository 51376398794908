.cardWrapper {
  width: 100%;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.img {
  width: 100%;
  object-fit: contain;
}
.date {
  font-style: italic;
}
.intro {
  flex-grow: 1;
}
.link {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #ff9c31;
  cursor: pointer;
}
.arrowIcon {
  font-size: 1.25rem;
}

@media screen and (min-width: 768px) {
  .cardWrapper {
    width: 60%;
    scroll-snap-align: center;
  }
}

@media screen and (min-width: 992px) {
  .cardWrapper {
    width: 502px;
    flex-shrink: 0;
    scroll-snap-align: center;
  }
}
