.mobileWrapper {
  width: 90%;
  padding: 4.5rem 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.link {
  text-decoration: none;
  color: #ff9c31;
}
.logo {
  width: 30%;
  object-fit: contain;
  align-self: center;
  margin: 0.5rem 0;
}

@media screen and (min-width: 768px) {
  .desktopWrapper {
    width: 100%;
    max-width: 1200px;
    padding: 4.5rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3rem;
  }
  .txtWrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .logo {
    height: 130px;
    width: auto;
    flex-grow: 0;
  }
}

@media screen and (min-width: 992px) {
  .desktopWrapper {
    width: 100%;
    max-width: 1200px;
    padding: 4.5rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3rem;
  }
  .txtWrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .logo {
    height: 130px;
    width: auto;
    flex-grow: 0;
  }
}
