.sectionWrapper {
  z-index: 2;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 97vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  z-index: 2;
}
.heading {
  font-size: 2.5rem;
  font-weight: 700;
  text-align: center;
  letter-spacing: 0.05rem;
  z-index: 3;
  color: white;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.6), -1px -1px 4px rgba(0, 0, 0, 0.6);
}
.span {
  font-size: 1.25rem;
  text-align: center;
  letter-spacing: 0.02rem;
  margin-top: 1rem;
}
.linkWrapper {
  display: none;
}

@media screen and (min-width: 768px) {
  .heading {
    font-size: 3rem;
  }
  .linkWrapper {
    display: flex;
    justify-content: space-between;
    background-color: #3c8dca;
    min-height: 6rem;
  }
  .link {
    flex-grow: 1;
    padding: 3rem 0 0 2rem;
    text-decoration: none;
    font-family: 'Poppins', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1.125rem;
    font-weight: 500;
    letter-spacing: 0.04rem;
    color: #ffffff;
    border: 0.05px solid white;
    cursor: pointer;
  }
  .active {
    color: #3c8dca;
    background-color: #d8e8f4;
  }
}

@media screen and (min-width: 992px) {
  .heading {
    font-size: 3.5rem;
    width: 60%;
    max-width: 680px;
    text-align: center;
  }
  .span {
    font-size: 1.5rem;
    margin-top: 2rem;
  }
  .link {
    font-size: 1.5rem;
  }
  .link:hover {
    color: #3c8dca;
    background-color: #d8e8f4;
  }
}
