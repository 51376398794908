.cardWrapper {
  width: 100%;
  padding: 2.5rem 1.5rem;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
}
.imgWrapper {
  align-self: center;
  width: 100%;
}
.img {
  width: 100%;
  object-fit: contain;
  border-radius: 50%;
}
.contentWrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.txtWrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 1rem;
}
.iconWrapper {
  display: flex;
  align-self: flex-end;
  gap: 1rem;
}
.iconCircle {
  padding: 0.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #ff9c31;
}
.icon {
  font-size: 1.5rem;
  color: white;
}

@media screen and (min-width: 992px) {
  .cardWrapper {
    width: 49%;
    flex-direction: row;
    justify-content: space-between;
    padding: 2.5rem;
  }
  .imgWrapper {
    width: 40%;
    flex-shrink: 0;
  }
  .contentWrapper {
    align-content: space-between;
  }
  .txtWrapper {
    align-items: flex-end;
    margin-top: 1.5rem;
  }
  .title {
    text-align: right;
  }
}
