.cardWrapper{
    min-width: 300px;
    padding: 3.5rem 2rem 2rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #ffffff;
}
.contentWrapper{
    display: flex;
    flex-direction: column;
    gap: 2.25rem;
}
.areaIcon{
    color: #cdcdcd;
    font-size: 4.3rem;
    margin-left: 2rem;
}
.txtWrapper{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.link{
    align-self: flex-end;
}
.arrowIcon{
    margin-top: 2rem;
    color: #ffffff;
    font-size: 3rem;
    background-color: #FF9C31;
    padding: 0.5rem;
    border-radius: 50%;
}

@media screen and (min-width: 768px) {
    .cardWrapper{
        width: 48%;
        align-self: stretch;
    }
}

@media screen and (min-width: 992px) {
    .link{
        cursor: pointer;
    }
    .cardWrapper{
        width: 47%;
        padding: 3.5rem 2.5rem 3.5rem 3.5rem;
    }
    .txt{
        width: 80%;
    }
    .arrowIcon{
        margin-top: 0;
    }
}