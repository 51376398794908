.sectionWrapper{
    gap: 4rem;
}
.contentWrapper{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.infoWrapper{
    padding: 3.5rem 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2.5rem;
    background-color: #1C3C74;
    border-radius: 1rem;
}
.icon{
    margin-bottom: 1rem;
    font-size: 4.5rem ;
    color: #ffffff;
}
.txtWrapper{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.lectureCardWrapper,
.lectureCardWrapper2{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

@media screen and (min-width: 768px) {
    .contentWrapper{
        flex-direction: row;
        flex-wrap: wrap;
    }
    .infoWrapper{
        width: 48%;
        padding: 3.5rem 2rem;
    }
    .lectureCardWrapper{
        flex: 1 0 48%;
    }
    .lectureCardWrapper2{
        flex-direction: row;
        flex-wrap: wrap;
    }
}

@media screen and (min-width: 992px) {
    .infoWrapper{
        padding: 6.5rem 3.5rem;
        max-height: 750px;
    }
}