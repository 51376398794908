.cardWrapper{
    padding: 2rem 2rem 4rem 2rem;
    border: 0.5px solid rgba(128, 128, 128, 0.3);
    border-radius: 0.2rem;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.1);
    -webkit-box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.15);
    margin-right: 0.25rem;
    margin-bottom: 0.6rem;
    display:flex;
    flex-direction: column;
    background-color: #ffffff;
}
.topWrapper{
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem;
}
.icon{
    font-size: 4rem;
    align-self: flex-end;
}
.bottomWrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
}
.readMore{
    margin-top: 2rem;
    color: #FF9C31; 
    text-decoration: underline;
    cursor: pointer;
}

@media screen and (min-width: 768px) {
    .cardWrapper{
        width: 48%;
        flex-shrink:0;
    }
}

@media screen and (min-width: 992px) {
    .cardWrapper{
        max-width: 32%;
        flex-shrink: 0;
    }
}