.sectionWrapper {
  align-items: center;
}
.img {
  display: none;
}
.formWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.9rem;
}
.formInput {
  width: 100%;
  padding: 0.9rem;
  border: 0;
  border-radius: 0.5rem;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
}
.textarea {
  width: 100%;
  height: 10rem;
  padding: 1rem;
  border: 0;
  border-radius: 0.5rem;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
}
textarea::placeholder {
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: grey;
}
.checkboxWrapper {
  display: flex;
  align-items: flex-start;
  gap: 0rem;
}
input[type='checkbox'] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
input[type='checkbox'] + span {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #ffffff;
  background-color: #ffffff;
  border-radius: 4px;
  margin-right: 8px;
  flex-shrink: 0;
}
input[type='checkbox']:focus + span {
  outline: auto;
}
input[type='checkbox']:checked + span {
  background-color: #ff9c31;
  border: 4px solid #ffffff;
}
.readMore {
  text-decoration: underline;
  cursor: pointer;
}
.btnWrapper {
  align-self: flex-end;
}
.submitted {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  padding: 3rem 2rem;
  margin: 1rem 0;
  width: 100%;
  background-color: #ffffff;
}
.button {
  padding: 0.5rem 1.2rem;
  background-color: #ffffff;
  border: 2px solid#FF9C31;
  border-radius: 1.5rem;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 500;
  text-transform: uppercase;
}
.button:hover {
  background-color: #ff9c31;
}
@media screen and (min-width: 768px) {
  .submitted {
    min-width: 400px;
  }
  .formWrapper {
    max-width: 550px;
  }
}

@media screen and (min-width: 992px) {
  .sectionWrapper {
    gap: 2.5rem;
  }
  .img {
    display: inline;
    width: 100%;
    object-fit: contain;
    border-radius: 1.5rem;
    max-width: 450px;
  }
}
