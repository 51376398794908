.icon {
  margin-bottom: 1rem;
  font-size: 3.5rem;
  color: black;
}
.txtWrapper {
  display: flex;
  flex-direction: column;
}
.company {
  color: #ff9c31;
  font-size: 1rem;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 0.145rem;
  text-transform: uppercase;
  margin: 0.5rem 0 1rem 0;
}

@media screen and (min-width: 992px) {
  .wrapper {
    width: 47%;
    padding: 1rem 0 0 0;
  }
}
