@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;1,300&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;
  background-color: #ffffff;
}
.pageWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.sectionWrapper {
  width: 90%;
  padding: 4.5rem 0;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.paragraphWrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

/* BACKGROUNDS */
.background {
  width: 100%;
  display: flex;
  justify-content: center;
}
.bgM1 {
  background-color: #d8e8f4;
}
.bgM2 {
  background-color: #9dc5e4;
}
.bgM3 {
  background-color: #3c8dca;
}
.bgD {
  background-color: #1c3c74;
}
.bgA {
  background-color: #ff9c31;
}

/*TEXT*/
.bodyTxt {
  font-size: 1rem;
  font-weight: 400;

  color: #000000;
  line-height: 150%;
  letter-spacing: 0.04rem;
  text-decoration: none;
}
.smallTxt {
  font-size: 0.875rem;
}
.mediumTxt {
  font-size: 1.125rem;
}
.titleTxt {
  font-size: 1.25rem;
  font-weight: 600;

  color: #000000;
  line-height: 150%;
  letter-spacing: 0.04rem;
  text-decoration: none;
}
.largeTxt {
  font-size: 1.5rem;
  font-weight: 600;

  color: #000000;
  line-height: 150%;
  letter-spacing: 0.04rem;
  text-decoration: none;
}
.mediumBold {
  font-weight: 500;
}
.semiBold {
  font-weight: 600;
}
.leftTxt {
  text-align: left;
}
.justifyTxt {
  text-align: justify;
}
.centerTxt {
  text-align: center;
}
.accentTxt {
  color: #ff9c31;
}
.whiteTxt {
  color: #ffffff;
}
.italicTxt {
  font-style: italic;
}
.quoteTxt {
  color: #000000;
  font-size: 0.875rem;
  font-style: italic;
  font-weight: 300;
  line-height: 150%;
  letter-spacing: 0.04rem;
  text-align: center;
}
.quoteTxtBold {
  color: #000000;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0.04rem;
}
.quoteBy {
  padding-top: 0.5rem;
  align-self: flex-end;
}
.btnTxt {
  font-weight: 500;
}

/*MODAL*/
body.ReactModal__Body--open {
  overflow: hidden;
}
.modalWrapper {
  width: 100%;
  margin-top: 3vh;
  max-height: 95vh;
  border: 1px solid #ccc;
  background: #fff;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 16px;
}
.modalOverlay {
  position: fixed;
  display: flex;
  justify-content: center;
  z-index: 4;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

@media screen and (min-width: 768px) {
  .modalWrapper {
    max-width: 800px;
  }
}

@media screen and (min-width: 992px) {
  .sectionWrapper {
    max-width: 1200px;
    padding: 6.5rem 0;
  }
  .modalWrapper {
    max-height: 90vh;
  }
  .paragraphWrapper {
    text-align: justify;
  }
}
