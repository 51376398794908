.bgLogo{
    background-image: url('../../images/logos/footer_bg_logo.png');
    background-repeat: no-repeat;
    background-position: 100% 100%;
    background-size: contain;
}
.sectionWrapper{
    width: 90%;
    padding:6.5rem 0 3.5rem 1rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
}  
.infoWrapper{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}
.infoWrapperDesktop{
    display: none;
}
.socialMediaWrapper{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}
.socialMedia{
    display: flex;
    align-items: center;
    gap: 1rem;
}
.socialMediaTxtWrapper{
    display: flex;
    flex-direction: column;
}
.iconCircle{
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 0;
    border-radius: 50%;
    background-color: #FF9C31;
}
.icon{
    font-size: 2rem;
    color: white;
}
.copyrightWrapper{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    text-align:end;
}

@media screen and (min-width: 768px) {
    .sectionWrapper{
        padding:6.5rem 0 3.5rem 1.5rem;
    }
    .rightContent{
        display: flex;
        justify-content: flex-end;
    }
    .copyrightWrapper{
        flex-direction: row;
    }
}

@media screen and (min-width: 992px) {
    .sectionWrapper{
        max-width: 1200px;
        padding:6.5rem 0 3.5rem 0;
        flex-direction: row;
        justify-content: space-between;
    }
    .socialMediaWrapper{
        padding-bottom: 4rem;
    }
    .iconCircle{
        cursor: pointer;
    }
    .infoWrapper{
        display: none;
    }
    .rightContent{
        flex-direction: column;
        justify-content: space-between;
    }
    .infoWrapperDesktop{
        display: flex;
        flex-direction: column;
        text-align: end;
        gap: 0.5rem;
    }

}