.headingWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}
.topWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}
.logoWrapper {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.sectionLine {
  background-color: #ff9c31;
  height: 2px;
  width: 1.8rem;
}
.logo {
  width: 1.5rem;
}
.sectionHeadingSmall {
  color: #ff9c31;
  font-size: 1rem;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 0.145rem;
  text-transform: uppercase;
  text-align: center;
}
.sectionHeadingLarge {
  color: #000000;
  font-size: 2.25rem;
  font-weight: 700;
  line-height: 110%;
  text-align: center;
}
.white {
  color: #ffffff;
}

@media screen and (min-width: 992px) {
  .sectionHeadingLarge {
    font-size: 3rem;
    text-align: left;
  }
}
