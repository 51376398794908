.cardWrapper {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 1rem;
  box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.25);
}
.img {
  width: 100%;
  object-fit: cover;
  border-radius: 1rem 1rem 0 0;
}
.txtWrapper {
  padding: 4rem 1.5rem 1.5rem 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  justify-content: space-between;
  gap: 1rem;
  position: relative;
}
.activityTitle {
  max-width: 2900px;
}
.iconCircle {
  position: absolute;
  top: -2rem;
  left: 42%;
  background-color: #1f1f1f;
  padding: 0.8rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
}
.icon {
  color: #ffffff;
  font-size: 2.5rem;
}
.iconArrow {
  align-self: flex-end;
  color: #ffffff;
  font-size: 3rem;
  background-color: #ff9c31;
  padding: 0.5rem;
  border-radius: 50%;
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .cardWrapper {
    width: 40%;
    flex-grow: 1;
  }
}

@media screen and (min-width: 992px) {
  .cardWrapper {
    width: 30%;
  }
  .img {
    min-height: 300px;
  }
}
