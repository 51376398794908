.background {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.sectionWrapper {
  display: flex;
  flex-direction: column;
  width: 90%;
  gap: 1.5rem;
  padding: 6.5rem 0;
}
.border {
  width: 100%;
  height: 100px;
  background-color: #d8e8f4;
}
.iconWrapper {
  display: flex;
  align-self: center;
  gap: 1rem;
}
.iconCircle {
  padding: 0.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #ff9c31;
}
.icon {
  font-size: 1.5rem;
  color: white;
}
.contentWrapper {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.img {
  width: 100%;
  object-fit: contain;
}
.txtWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}
.topWrapper {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-bottom: 0.5rem;
}
.topTxtWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.titleWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.2rem;
}
.title {
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.145rem;
  text-transform: uppercase;
}
.center {
  text-align: center;
}
.subjectWrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  margin-top: 1.5rem;
  margin-left: 1rem;
}
.subjectsHeading {
  margin-left: 0.5rem;
}
.subject {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.checkIcon {
  font-size: 2rem;
}

@media screen and (min-width: 768px) {
  .sectionWrapper {
    max-width: 1200px;
    padding: 4.5rem 0;
  }
  .contentWrapper {
    flex-direction: row;
  }
  .imgWrapper {
    width: 40%;
    flex-shrink: 0;
  }
  .topWrapper {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
  .titleWrapper {
    align-items: flex-start;
  }
  .title {
    color: #ff9c31;
  }
  .txtWrapper {
    gap: 1rem;
  }
  .subjectWrapper {
    margin-top: 1.5rem;
    margin-left: 2rem;
  }
}

@media screen and (min-width: 992px) {
  .sectionWrapper {
    padding: 6.5rem 0;
  }
  .contentWrapper {
    gap: 4rem;
  }
  .topTxtWrapper {
    align-items: flex-start;
  }
  .imgWrapper {
    width: 40%;
    flex-shrink: 0;
  }
  .txtWrapper {
    align-items: flex-start;
  }
}
