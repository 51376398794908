.modalWrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.icon {
  align-self: flex-end;
  font-size: 2.5rem;
  cursor: pointer;
}
.contentWrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0rem 1rem 1rem 1rem;
}

@media screen and (min-width: 992px) {
  .contentWrapper {
    padding: 0rem 5rem 5rem 5rem;
  }
}
