.sectionWrapper {
  gap: 2.5rem;
}
.imgWrapper {
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.img {
  width: 100%;
  object-fit: cover;
}
.txtWrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

@media screen and (min-width: 768px) {
  .txtWrapper {
    padding-top: 1rem;
  }
}

@media screen and (min-width: 992px) {
  .sectionWrapper {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  .imgWrapper {
    width: 45%;
    max-width: 450px;
    flex-shrink: 0;
  }
  .img {
    height: 100%;
  }
}
