.background {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.sectionWrapper {
  width: 90%;
  padding: 6.5rem 0;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.topContentWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
}
.iconWrapper {
  display: flex;
  align-self: center;
  gap: 1rem;
}
.iconCircle {
  padding: 0.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #ff9c31;
}
.icon {
  font-size: 1.5rem;
  color: white;
}
.contentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}
.imgWrapper {
  width: 100%;
  flex-shrink: 0;
}
.img {
  width: 100%;
  object-fit: contain;
  border-radius: 1rem;
}
.projectWrapper {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

@media screen and (min-width: 768px) {
  .iconCircle {
    padding: 1rem;
  }
  .icon {
    font-size: 2rem;
  }
}

@media screen and (min-width: 992px) {
  .sectionWrapper {
    max-width: 1200px;
  }
  .topContentWrapper {
    flex-direction: row;
    align-items: flex-start;
    gap: 7rem;
  }
  .contentWrapper {
    flex-direction: row;
    align-items: flex-start;
  }
  .imgWrapper {
    width: 400px;
    flex-shrink: 0;
  }
  .iconWrapper {
    align-self: flex-start;
  }
  .projectWrapper {
    flex-direction: row;
    flex-wrap: wrap;
  }
}
