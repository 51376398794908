.sectionWrapper {
  align-items: center;
}
.cardsWrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

@media screen and (min-width: 768px) {
  .cardsWrapper {
    flex-direction: row;
    flex-wrap: wrap;
  }
}
