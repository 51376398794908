.modalWrapper{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.icon{
    align-self: flex-end;
    font-size: 2.5rem;
    cursor: pointer;
}
.contentWrapper{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0rem 0.5rem 1rem 0.5rem;
}
.img{
    width: 100%;
    object-fit: contain;
    align-self: center;
    margin-bottom: 1.5rem;
}
.galleryWrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1px;
    margin-bottom: 1.5rem;
}
.displayWrapper{
    display:flex;
    position: relative;
}
.btn {
    position: absolute;
    top: 40%;
    padding: 0.5rem;

    display: flex;
    justify-content: center;
    align-items: center;
    color: white;

    background-color: transparent;
    border: none;

    border-radius: 50%;

    z-index: 1;
    font-size: 35px;

    cursor: pointer;
}
.btnLeft{
    left: -0.5rem;
}
.btnRight{
    right: -0.5rem;
}

.displayImg{
    width: 100%;
    object-fit: contain;

}
.thumbnailWrapper{
    display: flex;
    flex-wrap: wrap;
}
.thumbnail{
    width: 33.3%;
    object-fit: cover;
    border:0.1px solid white;
}
.thumbnailActive{
    border:2px solid #FF9C31;
}



.title{
    text-transform: uppercase;
    font-weight: 500;
}


@media screen and (min-width: 768px) {
    .btn{
        top: 45%;
    }
    .thumbnail{
        width: 25%;
    }
}

@media screen and (min-width: 992px) {
    .contentWrapper{
        padding: 0rem 5rem 5rem 5rem;
    }
    .img{
        /* width: 90%; */
        object-fit: contain;
        align-self: center;
        margin-bottom: 1.5rem;
    }
    .btnLeft{
        left: 1rem;
    }
    .btnRight{
        right: 1rem;
    }
    .thumbnail{
        width: 20%;
    }
    .thumbnailActive{
        border:3px solid #FF9C31;
    }

}