.sectionWrapper {
  gap: 2.5rem;
}
.imgWrapper {
  width: 100%;
}
.img {
  width: 100%;
  object-fit: contain;
}
.txtWrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.quoteWrapper {
  width: 90%;
  align-self: center;
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 768px) {
  .txtWrapper {
    padding-top: 3rem;
  }
}

@media screen and (min-width: 992px) {
  .sectionWrapper {
    flex-direction: row-reverse;
  }
  .imgWrapper {
    width: 45%;
    flex-shrink: 0;
    max-width: 450px;
  }
  .img {
    max-width: 450px;
  }
  .txtWrapper {
    text-align: justify;
  }
  .quoteWrapper {
    width: 70%;
  }
}
