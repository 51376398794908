.button {
  align-self: center;
}
.button {
  padding: 0.7rem 1.5rem;
  margin-top: 2rem;
  background-color: #ff9c31;
  border: none;
  border-radius: 1.5rem;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #ffffff;
  font-size: 1rem;
  font-weight: 500;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.button:hover {
  transform: scale(1.02);
}
