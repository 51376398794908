.background {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #ffffff;
}
.cardWrapperM {
  display: flex;
  flex-direction: column;
}
.cardContainerM {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
}
.cardWrapperD {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-bottom: 0.25rem;
}
@media screen and (min-width: 768px) {
  .arrowWrapper {
    align-self: flex-end;
    display: flex;
    gap: 1rem;
  }
  .btn {
    background-color: #ff9c31;
    color: #ffffff;
    font-size: 35px;
    padding: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 50%;
  }
  .btnLeft {
    padding-right: 0.25rem;
  }
  .btnRight {
    padding-left: 0.25rem;
  }
  .btnDisabled {
    background-color: rgba(255, 155, 49, 0.5);
  }
  .cardContainerD {
    display: flex;
    overflow-x: scroll;
    gap: 0.8rem;
  }
  .cardContainerD::-webkit-scrollbar {
    display: none;
  }
}

@media screen and (min-width: 992px) {
  .sectionWrapper {
    gap: 1.5rem;
  }
}
