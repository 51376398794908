.cardWrapperM{
    display: flex;
    flex-direction: column;
}
.cardContainerM{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

@media screen and (min-width: 768px) {
    .cardWrapperD{
        display: flex;
        position: relative;
        padding-top: 2rem;
    }
    .cardContainerD {
        overflow-x: scroll;
        display: flex;
        gap: 1rem;
        scroll-behavior: smooth;
        padding-bottom: 1rem;
        scroll-snap-type: x mandatory;
    }
    .cardContainerD::-webkit-scrollbar {
        display: none;
    }
    .btn {
        position: absolute;
        top: 38%;
        padding: 0.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        background-color: rgba(255, 155, 49, 0.8);
        border: none;
        border-radius: 50%;
        z-index: 1;
        font-size: 35px;
        cursor: pointer;
    }
    .btnLeft{
        left: 1rem;
    }
    .btnRight{
        right: 1rem;
    }
}

@media screen and (min-width: 992px) {
    .cardWrapperD{
        padding-top: 4rem;
    }
    .cardContainerD {
        gap: 4.5rem; 
    }
}