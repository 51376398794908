.backgroundWrapper {
  width: 100%;
  padding: 6rem 0;
  display: flex;
  justify-content: center;
  background-image: url('../../images/home_mood.png');
  background-size: cover;
}
.sectionWrapper {
  width: 90%;
  padding: 6.5rem 0;
  display: flex;
  justify-content: flex-end;
}
.cardWrapper {
  width: 385px;
  padding: 10rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  background-color: #ffffff;
  border-radius: 1.25rem;
}
.img {
  width: 25%;
  object-fit: contain;
}
.txtWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  text-align: center;
}

@media screen and (min-width: 992px) {
  .backgroundWrapper {
    padding: 0 0;
  }
  .sectionWrapper {
    max-width: 1200px;
  }
}
