.contentWrapper{
  display: flex;
  flex-direction: column;
}
.imgWrapper{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap:0.5rem;
  margin-bottom: 2.5rem;
}
.img{
  width: 100%;
  object-fit: contain;
}
.logo{
  width: 70%;
  align-self: center;
  margin: 0.5rem 0;
}
.link{
  text-decoration: none;
  color: #FF9C31;
}

@media screen and (min-width: 768px) {
  .logo{
    width: 50%;
    margin: 1.5rem 0;
  }
}

@media screen and (min-width: 992px) {
  .introTxt{
    max-width: 1000px;
    align-self: center;
  }
  .contentWrapper{
    flex-direction: row-reverse;
    gap: 2rem;
    margin-top:0.5rem;
  }
  .logo{
    margin: 0.5rem 0;
  }
  .imgWrapper{
      width: 49%;
      display: flex;
      flex-shrink:0;
  }
  .img{
    height: 100%;
    object-fit: cover;
  }
}