.sectionWrapper {
  width: 90%;
  padding: 6.5rem 0;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}

@media screen and (min-width: 768px) {
  .sectionWrapper {
    gap: 1rem;
  }
  .sectionWrapper > p {
    margin-bottom: 1.5rem;
  }
}

@media screen and (min-width: 992px) {
  .sectionWrapper {
    max-width: 1200px;
  }
}
