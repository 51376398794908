.cardWrapper,
.cardWrapper2 {
  position: relative;
}
.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 1rem;
  box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.25);
}
.lectureTitle {
  position: absolute;
  bottom: 1rem;
  left: 2rem;
  padding-right: 2rem;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5), -1px -1px 4px rgba(0, 0, 0, 0.5);
}

@media screen and (min-width: 768px) {
  .cardWrapper {
    flex-grow: 1;
  }
  .cardWrapper2 {
    width: 48%;
  }
  .cardWrapper2:nth-child(even) {
    flex-grow: 1;
  }
}

@media screen and (min-width: 992px) {
  .cardWrapper,
  .cardWrapper2 {
    cursor: pointer;
    max-height: 365px;
  }
  .cardWrapper:hover,
  .cardWrapper2:hover {
    transform: scale(1.02);
  }
}
