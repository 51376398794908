.modalWrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.icon {
  align-self: flex-end;
  font-size: 2.5rem;
  cursor: pointer;
}
.contentWrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0rem 1rem 1rem 1rem;
}
.img {
  width: 100%;
  object-fit: contain;
  align-self: center;
  margin-bottom: 1.5rem;
}
.title {
  font-size: 1.5rem;
  text-transform: uppercase;
  font-weight: 500;
}
.topWrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.quoteIcon {
  align-self: center;
  font-size: 4rem;
  margin-bottom: 2.5rem;
}

@media screen and (min-width: 992px) {
  .contentWrapper {
    padding: 0rem 5rem 5rem 5rem;
  }
  .img {
    width: 80%;
    object-fit: contain;
    align-self: center;
    margin-bottom: 1.5rem;
  }
}
